


.dropdown-basic{
  display: flex;
  flex-direction: column;
  position: relative;
}
.dropdown-basic.closed .dropdown-basic__body{
  display:none;
}
.dropdown-basic__header{
  display: flex;
  place-content: space-between;
  border-bottom: 3px solid #4D4D4D;
  cursor: pointer;
}
.dropdown-basic__header-box{
  display: flex;
  gap:5px;
  place-content: center;
  place-items:center;
  width: max-content;
  border-bottom: 3px solid var(--quinary-color);
  transform: translateY(3px);
}
.dropdown-basic__header .buttons{
  transform: translateY(10px);
  height: min-content;
}
.dropdown-basic__header-box path{
  stroke:var(--white);
}

.dropdown-basic.closed .dropdown-basic__header-box svg{
  transform: rotate(-90deg);
  transition: all 0.2s ease-in-out ;
}
.dropdown-basic .dropdown-basic__header-box svg{
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out ;
}
.dropdown-basic__body{
  
}