.modal{
  z-index: 1000000000000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  display: grid;
  place-items: center;
  animation:appear 0.3s 1 ease-in-out;
}
.modal__content{
  background-color: var(--tertiary-color);
  width: calc(100% - 40px);
  height: 100%;
  max-height: min-content;
  max-width: 700px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 4.1px 8.2px 8.2px hsl(0deg 0% 0% / 0.37);
  position: relative;
  overflow-y: auto;
  
}
@keyframes disappear{
  to{
    opacity:0;
    transform: scale(0);
  }
}
@keyframes appear{
  from{
    opacity:0;
    transform: scale(0);
  }
  to{
    opacity:1;
    transform: scale(1);
  }
}
.modal__loader{
  width:100%;
  height:100%;
  display:grid;
  place-content:center;
}
.modal.disappear{
  animation:disappear 0.3s 1 ease-in-out;
}

.modal__header{
  min-height: 100px;
  min-width: 300px;
  display: flex;
  place-items: center;
}
[data-modal-close="true"]{
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0;
  border-top-right-radius: 6px;
  background-color: var(--quinary-color);
}
[data-modal-loader="true"]{
  border-radius: 5px;
}