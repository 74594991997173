.loader{
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  place-content: center;
  place-items: center;
  border-radius: 10px;
  background-color: black;
  /* backdrop-filter: blur(4px); */
  /* -webkit-backdrop-filter: blur(4px); */
  z-index: 9800;
}

.loader__body{
  place-items: center; 
  display: grid;
  gap: 1rem;
  position: sticky;
  top: 0;
}
.loader__text{
  display: flex;
  place-content: center;
}
.loader__text *{
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  width: 85%;
  margin:0;
}

.loader__text >p::after{
  content:"";
  animation: loader-text 4s infinite linear;
}

.loader.-bar .loader__text *{
  width: 100%;
}
.loader.-bar .loader__text > p::after{
  animation: none;
}
.loader.-bar .loader__text{
  flex-direction: column;
  place-items: center;
}
.loader-background{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00000050;
  display: flex;
  place-items: center ;
  place-content: center;
  z-index: 100;
}

@keyframes loader-text {
  0% {
    content:"";
  }
  25% {
    content:".";
  }
  50% {
    content:"..";
  }
  75% {
    content:"...";
  }
}