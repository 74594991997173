/*Spinner de carga  */
.spinner {
  width: 26px;
  height: 26px;
  display: grid;
  border: 3px solid #0000;
  border-radius: 50%;
  border-color:  var(--tertiary-color) #0000;
  animation: spinnerRotate 1s infinite linear;
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  margin: 2.2px;
  border: inherit;
  border-radius: 50%;
}

.spinner::before {
  border-color: #ffff #0000;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}

.spinner::after {
  margin: 5px;
}

@keyframes spinnerRotate {
  100% {
    transform: rotate(1turn);
  }
}