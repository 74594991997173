f.footerMain{
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;
  padding: 30px 0;
  width: 100%;
  background-color: var(--secondary-color);
  margin-top: 90px;
}
.footerMain__marca{
  display: flex;
  place-items: center;
  place-content: center;
}
.footerMain__logo{
  display: flex;
  place-items: center;
  place-content: center;
  width:100px;
  height: 40px;
}
.footerMain__logo svg{
  transform: scale(0.6);
}
.footerMain__logo-text{
  display: flex;
  place-items: center;
  place-content: center;
  width: max-content;
  padding-left: 20px;
  border-left: 1px solid var(--white) ;
  font-size: 12px;
}
 
.separador{
  width:90%;
  background-color: var(--white);
  height: 1px;
}
.footer__links{
  list-style: none;
  display: flex;
  place-items: center;
  place-content: space-evenly;
  width: 90%;
}
.footer__li{
  padding: 10px;
}
.footer__link{
  text-decoration: none;
}
@media screen and (max-width:600px){
  .footer__links{
    flex-direction: column;    
    padding: 0;
  }
  .footerMain__logo-text{
    font-size: 11px;
  }
  
  .footerMain__logo-text{
    padding-left: 10px;
  }
  .footerMain__logo {
    display: flex;
    place-items: center;
    place-content: center;
    width: 70px;
  }
  .footer__li{
    padding: 30px;
  }
  
}
@media screen and (max-width:380px){
  .footerMain__marca{
    flex-direction: column;
  }
  .footerMain__logo-text{
    border: none;
    padding: 0;
  }
}