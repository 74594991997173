.form-container{
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr;
  place-content: center;
  place-items: center;
  --max-width:480px;
  --field-color:#ffff;
  --caret-color:#000000;
  --field-text-color:#000000;
}
.form-container__title{
  grid-area: title;
  width: 100%;
  text-align: left;
  margin: 20px 0;
  max-width: var(--max-width);
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}
.form-container__fields{
  position: relative;
  grid-area: fields;
  display: flex;
  place-content: center;
  flex-direction: column;
  width: 90%;
  padding: 10px;
  border-radius: 5px;
  animation:aparecer 0.5s 1 linear;
  max-width: var(--max-width);
  margin-bottom: 5px;
}
.form-container__body{
  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-area: body;
  width: 100%;
}
.form-container__submit-container{
  grid-area: submit;
  width: 100%;
  display: flex;
  place-content: center;
  place-items: center;
  max-width:  var(--max-width);
  gap: 24px;
  flex-wrap: wrap;
}
.form-container__submit-button{
  background-color: var(--quinary-color);
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 5px 10px;
  height: 32px;
  width: max-content;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  color: #FFFFFF;
  text-decoration: none;
}
.form-container__submit-button:disabled{
  opacity: 0.4;
}
.form-container .field{
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.form-container .field__label{
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
}
.form-container .field__label em{
  color:var(--gray);
  font-weight: normal;
}
.field__textarea::-moz-selection,
.field__text::-moz-selection {
  background: #7D299FA3;
}
.field__textarea ::selection,
.field__text::selection {
  background: #7D299FA3;
}
.field__textarea:disabled,
.field__text:disabled{
  cursor: not-allowed;
  user-select: none;
}
.field__textarea,
.field__text{
  padding: 10px;
  background-color: var(--field-color);
  caret-color: var(--caret-color);
  color:var(--field-text-color);
  border:none;
  border-radius: 5px;
  height: 29px;
  font-size: 16px;
  resize: none;
  z-index: 0;
}
.field__textarea{
  resize: vertical;
  min-height: 60px;
  max-height: 300px;
  form-sizing:content;
}
.field__textarea:hover,
.field__text:hover{
  opacity: 1!important;
}
.field__textarea:not(:focus),
.field__text:not(:focus){
  opacity: 0.5;
}
.field__textarea:focus-visible,
.field__text:focus-visible{
  outline: none;
}
.field__textarea:focus::placeholder,
.field__text:focus::placeholder{
  opacity: 0;
}
.field__textarea::placeholder,
.field__text::placeholder{
  color: rgba(0, 0, 0, 0.483);
}

.field__radio-input{
  appearance: none;
  background-color: #ffffff;
}
.field__radio-label{
  padding: 5px;
  position: relative;
  cursor: pointer;
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
}

.field__radio-mark{
  width: 15ch;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  border: 5px;
  text-align: left;
  color: var(--black);
}
.field__radio-input:checked + .field__radio-label > .field__radio-mark{
  color: var(--white);
}
.field__radio-input:checked + .field__radio-label{
  background-color: var(--quinary-color);
  color: #ffffff;
}
@media screen and (max-width:375px) {
  .field__textarea,
  .field__text{
    font-size: 15px;
  }
  .form-container .field__label{
    font-size: 15px;
  }
  .form-container__title{
    margin: 15px 0;
  }
}

.field__number,
.field__date,
.field__time{
  padding: 10px;
  background-color: var(--field-color);
  caret-color: var(--caret-color);
  color:var(--field-text-color);
  border:none;
  border-radius: 5px;
  height: 29px;
  font-size: 16px;
  resize: none;
  z-index: 0;
  cursor: pointer;
}
.field__number{
  width: 5ch;
}
.field__number:hover,
.field__date:hover,
.field__time:hover{
  opacity: 1!important;
}
.field__number:not(:focus),
.field__date:not(:focus),
.field__time:not(:focus){
  opacity: 0.5;
}
.field__number:focus-visible,
.field__date:focus-visible,
.field__time:focus-visible{
  outline: none;
}
.field__number:focus::placeholder,
.field__date:focus::placeholder,
.field__time:focus::placeholder{
  opacity: 0;
}
.field__number::placeholder,
.field__date::placeholder,
.field__time::placeholder{
  color: rgba(0, 0, 0, 0.483);
}
.field-inline{
  margin-bottom: 0;
  flex-direction: row;
  display: flex; 
  gap: 5px;
}