.nav{
  display: flex;
  height: 70px;
  width: 100%;
  position: fixed;
  top:0;
  left:0;
  margin: auto;
  z-index: 10000;
  place-content: center;
  transition: all 0.5s ease;
  place-content: space-around;
  background-color: var(--tertiary-color);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
}
.nav__ul{
  display: flex;
  gap:10px;
  padding: 10px;
  margin: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  place-items: center;
}
.nav__li{
  list-style: none;
  display: flex;
  place-items: center;
  padding: 10px;
  height: 37px;
  border-radius: 5px;
  transition: all 0.1s ease-in-out;
}
.nav__li.active{
  background-color: var(--black)!important;
}
.nav__li.active.variant2{
  background-color: var(--secondary-color)!important;
}
.nav__li.variant3 {
  background-color: var(--white)!important;
}
.nav__li.variant3 .nav__a-text{
  color: var(--quinary-color);
}

.nav__a{
  text-decoration: none;
  color: #ffff;
  text-align: center;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  place-content: center;
  display: flex;
  gap: 5px;
  pointer-events: fill;
  place-items: center;
}
.nav__a span{
  height: min-content;
}
.nav__button{
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: none;
  width: min-content;
  height: min-content;
  margin: 10px;
}
.nav__logo{
  padding: 10px;
  font-weight: 900;
  font-size: 1.5em;
  text-align: center;
  color: #ffff;
  z-index: 9;
  text-shadow:2px 1.5px var(--tertiary-color);
  display: flex;
  place-items: center;
  font-family: "Montserrat-Alt1";
  font-weight: 800;
  font-style: normal;
}
.nav__ul.show{
  right: 0;
  opacity: 1;
}
.nav-side__section{
  display: flex;
  place-items: center;
  place-items: flex-start;
  width: 100%;
  flex-direction: column;
}

.nav-side__section:after{
  content:"";
  width: 80%;
  margin: auto;
  height: 1px;
  background-color:var(--white) ;
}
.nav-side__section .nav__a{
  display:flex;
  place-content: start;
  width: 100%;
  height: 100%;
  padding: 30px 0px 30px 30px;
}
.nav-side__ul{
  display: flex;
  flex-direction: column;
  height:100vh;
  position: fixed;
  top:0;
  left:0;
  margin-top: 70px;
  width: 220px;
  background-color: var(--tertiary-color);
  z-index: 9999;
  transform: translateX(-100%);
  transition:transform 0.5s ease-in-out ;
}
.nav-side__ul.show{
  transition:transform 0.5s ease-in-out ;
  transform: translateX(0%);
}
.nav__a-text{
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
}
.nav__opener{
  display: none;
  position: absolute;
  top: 20px;
  left: 30px;
  width: 32px ;
  height: 32px ;
  background-color: transparent;
}


@media screen and (max-width:560px) {
  .nav__ul{
    display: none!important;
  }
  .nav__opener{
    display: flex;
  }
}
@media screen and (min-width:560px) {
  .nav-side__ul.show{
    transform: translateX(-100%)!important;
  }
}
