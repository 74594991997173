.info-container{
  padding: 10px;
  width: 100%;
  display: flex;
  place-items: center;
  place-content: center;
  position: relative;
}
.info-icon{
  position: absolute;
  top: 10px;
  left:0;
  width: 18px;
  height:18px;
  border-radius: 50%;
  background-color: var(--white);
}
.info-icon svg{
  transform: translate(-2px,-1.5px);
}
.info-text{
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  margin: 5px;
  max-width: 450px;
  margin-left: 21px;
}