
.switch-basic{
  background: transparent;
  padding: 5px;
  color: var(--disabled-color);
  border-radius: 15px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  width: min-content;
  transition: all 0.2s ease-in-out;
  display: flex;
  place-items: center;
  font-size: 12px;
  text-transform: uppercase;
  gap:9.5px;
  font-weight:700 ;
}
.switch-basic:hover{
  opacity:0.8;
}
.switch-basic__decorator{
  width: 35px;
  height: 16px;
  background-color: var(--disabled-color);
  border-radius: 100px;
  display: flex;
  place-items: center;
}
.switch-basic__decorator::before{
  content: "";
  width: 10px;
  height: 10px;
  border-radius:50%;
  background-color: var(--white);
  margin: 0 3px;
  transition: all 0.2s ease-in-out;
}

