@import "./ButtonBasic.css";
@import "./ButtonClose.css";
@import "./ButtonQuestion.css";
@import "./ButtonReturn.css";
@import "./LinkBasic.css";

.buttons{
  display: flex;
  place-items: center;
  place-content: center;
  gap:10px;
  flex-wrap: wrap;
}
.buttons_button{
  width: 40%;
  height: min-content;
  margin: 10px;
  min-width: min-content;
}
.buttons.grid3{
  display:grid;
  grid-template-columns: repeat(3,min-content);
  grid-template-areas: "first-button second-button third-button";
}
.buttons.grid2{
  display:grid;
  grid-template-columns: min-content min-content;
}
@media screen and (max-width:400px) {
  .buttons.grid3{
    grid-template-columns: min-content min-content;
    grid-template-areas: "first-button second-button"
                          "third-button third-button";
  }
}
.buttons.grid3 > *:nth-child(1){
  grid-area: first-button;
}
.buttons.grid3 > *:nth-child(2){
  grid-area: second-button;
}
.buttons.grid3 > *:nth-child(3){
  grid-area: third-button;
}