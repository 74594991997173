
.list-basic{
  margin: 0;
}
.list-basic *{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--white); 
}


