.modal-basic{
  position: fixed;
  width: 365px;
  height: 137px;
  display: flex;
  place-content: space-evenly;
  place-items: center;
  z-index: 9900;
  bottom: 10px;
  right: 10px;
  padding: 10px;
  background-color: var(--black);
  border-radius: 10px;
  border: 1px solid var(--white);
}
.modal-basic__header{
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
} 
.modal-basic__body{
  flex-direction: column;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
} 
.modal-basic__aside{
  padding:10px;
}
.modal-basic__close{
  position: absolute;
  top: 5px;
  right: 5px;
}
.modal-basic.disappear{
  opacity: 1;
  animation: dissappear 15s 1 ease-in-out;
}

@keyframes dissappear {
  to{
    opacity: 0;
  }
}
@media screen and (max-width:400px) {
  .modal-basic{
    width: 100%;
    width: 90%;
    margin: auto;
    right: unset;
  }

}
@media screen and (max-width:375px) {
  .modal-basic__header{
    font-size: 14px;
  }
  .modal-basic__body{
    font-size: 12px;
  }
  .modal-basic__aside{
    transform: scale(0.9);
  }
}
@media screen and (max-width:300px) {
  .modal-basic{
    height: min-content;
    flex-direction: column;
  }
}