.spinner-basic {
  font-size: 11px;
  width: 4em;
  height: 4em;
  display: grid;
  border-radius: 50%;
  border:0.364em solid rgba(255, 255, 255, 0.3);
  border-bottom-color:rgb(255, 255, 255);
  animation: spinnerRotate 1s infinite linear;
}

@keyframes spinnerRotate {  
  100% {
    transform: rotate(1turn);
  }
}
