.dropdown__container.closed{
  height: 46px;
  transition: height 0.2s ease-in-out;
}
.dropdown__container{
  overflow: hidden;
  position: relative;
  transition: all 0.2s ease-in-out;
  height: 486px;
}
.dropdown__container.closed .dropdown__content{
  position: absolute;
  transform: translateY(-160%);
  transition: all 0.2s ease-in-out;
}
.dropdown__content{
  transition: all 0.2s ease-in-out;
  padding: 15px;
  padding-right:0;
  width: 100%;
}
.dropdown__header{
  border-bottom: 1px solid var(--tertiary-color);
  cursor: pointer;
  background-color: var(--tertiary-color);
  padding: 10px;
  margin: 0;
  z-index: 100;
  position: relative;
  display: flex;
  place-items: center;
}
.dropdown__header h3{
  text-transform: uppercase;
  font-size:24px;
  margin: 0;
  margin-left:10px;
  -webkit-user-select: none;
  user-select: none;
}
.dropdown__container.closed .dropdown__header{
  background-color: var(--secondary-color);
}
.dropdown__container.closed .dropdown__header svg{
  transform: rotate(-90deg);
  transition:all 0.2s ease-in-out
}
.dropdown__header svg{
  transform: rotateX(0deg);
  transition:all 0.2s ease-in-out
}




@media screen and (max-width:375px) {
  .dropdown__header span{
    font-size: 20px;
  }
}