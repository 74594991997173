@import "./SwitchBasic.css";
@import "./SwitchBasicInput.css";
.switch-basic-block{
  display: flex;
  place-items: center;
  place-content: left;
  border: 2px solid var(--quinary-color);
  border-radius: 5px;
}
.switch-basic-option{
  display: flex;
  height: 100%;
  width: max-content;
}
.switch-basic-option label{
  padding: 5px;
  cursor: pointer;
  display: flex;
  place-items: center;
  background-color: transparent;
  color: var(--quinary-color);
  border: none;
  user-select: none;
  font-weight: bold;
}
.switch-basic-option input[type="radio"]{
  appearance: none;
  width: 0;
  height: 0;
}
.switch-basic-option input[type="radio"]:checked + label{
  background-color: var(--quinary-color);
  color: var(--white);
}