.select-alt{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
 }
 .select-alt.select-open{
   overflow: visible;
 }
 .select-alt.select-open .select-alt__container{
   display: flex;
 }
 .select-alt__container{
   display: none;
   flex-direction: column;
   position: absolute;
   bottom:0;
   left:0;
   transform: translateY(100%);
   width: 100%;
   z-index: 100;
 }
 .select-alt__selected:hover{
   opacity: 0.8;
   cursor: pointer;
 }
 
 .select-alt__selected{
   border:none;
   display: flex;
   place-content:space-between;
   gap:10px;
   height: 32px;
 }
 .select-alt__selected span{
   color:var(--white);
   text-align: left;
   font-size: 14px;
   line-height: 21px;
   font-weight: 500;
 }
 .select-alt__selected svg{
   transform:rotate(-90deg);
   transition:all 0.2s ease-in-out
 }
 .select-alt.select-open .select-alt__selected svg{
   transform:rotate(0deg);
   transition:all 0.2s ease-in-out
 }
 .select-alt__selected,
 .select-alt__option{
   color: var(--white);
   text-transform: capitalize;
   cursor: pointer;
   background-color: var(--main-color);
   padding:5px;
   text-align: left;
   font-size: 14px;
   line-height: 21px;
   font-weight: 500;
 }
 
.select-alt__option:hover{
  background-color: var(--tertiary-color);
  color: var(--white);
}
.select-alt__option.checked {
  display: none;
}
.select-alt.dark > .select-alt__selected{
  background-color: var(--tertiary-color);
  color:var(--white);
}
.select-alt.dark > .select-alt__selected span{
   color: var(--white);
}
.select-alt.dark .select-alt__option{
  background-color: var(--tertiary-color);
  color:var(--white);
}
.select-alt.dark .select-alt__option:hover{
  background-color: var(--white);
  color:var(--tertiary-color);
}
.select-alt.dark .select-alt__selected path {
  stroke:var(--white);
}