.pd0{
  padding: 0!important;
}
.m0{
  margin: 0;
}
.w100{
  width: 100%!important
}
.main-bg{
  background-color: var(--main-color)!important;
}
.secondary-bg{
  background-color: var(--secondary-color)!important;
}
.tertiary-bg{
  background-color: var(--tertiary-color)!important;
}
.quaternary-bg{
  background-color: var(--quaternary-color)!important;
}
.quinary-bg{
  background-color: var(--quinary-color)!important;
  color:var(--white)!important;
}
.transparent-bg{
  background-color: transparent!important;
}
.white-bg{
  background-color: var(--white)!important;
  color:var(--quinary-color)!important;
}
.black-bg{
  background-color: var(--black)!important;
  color:var(--white)!important;
}
.red-bg{
  background-color: var(--red)!important;
  color:var(--white)!important;
}
.green-bg{
  background-color: var(--green)!important;
  color:var(--white)!important;
}
.blue-bg{
  background-color: var(--blue)!important;
  color:var(--white)!important;
}
.orange-bg{
  background-color: var(--orange)!important;
  color:var(--white)!important;
}
.black-bg.t-50{
  background-color:  #00000080!important;
}

.mt10{
  margin-top: 10px!important;
}
.mt20{
  margin-top: 20px!important;
}
.mt30{
  margin-top: 30px!important;
}
.p0{
  padding: 0!important;
}
.p5{
  padding:5px;
}
.p10{
  padding:10px;
}
.p20{
  padding:20px;
}
.pt40{
  padding-top:40px;
}
.-circle{
  border-radius:50%;
  width: 30px;
  height: 30px;
}
.border-radius-0{
  border-radius: 0;
}
.border-radius-5{
  border-radius: 5px;
}
.all-bg{
  height: 100vh;
  width: 100vw;
  position: fixed;
}
.flex{
  display: flex;
}
.flex-wrap{
  flex-wrap:wrap
}
.flex-wrap-reverse{
  flex-wrap:wrap-reverse
}
.gap-0{
  gap:0;
}
.gap-5{
  gap:5px;
}
.gap-10{
  gap:10px;
}
.gap-20{
  gap:10px;
}
.select-none{
  user-select: none;
}
.cursor-default{
  cursor: default;
}
.content-center{
  place-content: center!important;
}
.items-center{
  place-items: center!important;
}
.items-start{
  place-items: start!important;
}
.text-center{
  text-align: center;
}
.text-left{
  text-align: left;
}
.h-full{
  height: 100vh;
}
.mw-600{
  max-width: 600px!important;
}
.mw-700{
  max-width: 700px!important;
}
.m-auto{
  margin:auto;
}
.m-0{
  margin:0;
}
.w-min-content{
  width: min-content;
}
.h-min-content{
  height: min-content;
}
.h-max-content{
  height: max-content;
}
.mw-480{
  max-width: 480px;
}
.display-none{
  display: none!important;
}
.flex-column{
  flex-direction: column;
}
.content-f-end{
  place-content: flex-end;
}
.white-c{
  color:var(--white)!important;
}
.black-c{
  color:var(--black)!important;
}
.gray-c{
  color:var(--gray)!important;
}
.red-c{
  color:var(--red)!important;
}
.border-basic{
  border:1px solid white;
}
.overflow-h{
  overflow:hidden;
}
.capitalize{
  text-transform: capitalize;
}