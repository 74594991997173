.switch-basic-input{
  appearance: none;
  position: absolute;
} 
.switch-basic-input:checked ~ .switch-basic > .switch-basic__decorator{
  background: var(--green);
  transition: all 0.2s ease-in-out;
}
.switch-basic-input:checked ~ .switch-basic {
  color: var(--green);
}
.switch-basic-input:checked ~ .switch-basic > .switch-basic__decorator::before{
  transform: translateX(20px);
  transition: all 0.2s ease-in-out;
}