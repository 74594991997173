.main-container{
  padding: 20px;
  padding-top: 25px;
  width: 90%;
  max-width: 700px;
  display: grid;
  place-content: center;
  place-items: center;
  grid-template-areas: "header header"
                       "body body";
  grid-template-columns: 1fr;
  gap:20px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 40px;
}
.main-container__header{
  grid-area: header;
  display: flex;
  width: 100%;
  place-items: center;
  place-content: center;
  flex-direction: column;
}
.main-container__header-text{
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  margin: 0;
}
.main-container__header-description{
  font-size: 18px;
  text-align: center;
  line-height: 22.5px;
  margin: 15px 3px;
}
.main-container__header-text:after{
  content:".";
  color:var(--tertiary-color);
}
.main-container__body{
  grid-area: body;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.main-container.loading{
  height:330px;
  overflow: hidden;
}

@media screen and (max-width:375px) {
  .main-container{
    font-size: 15px!important;
  }
  .main-container__header-text{
    font-size: 24px;
  }
  .main-container__header-description{
    font-size: 15px;
  }
}
