.list-basic-clean{
  margin: 0;
}
.list-basic-clean *{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--white); 
}
.list-basic-clean{
  list-style: none;
  padding-left: 20px;
}