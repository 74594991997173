.button-basic{
  width: max-content;
  height: 32px;
  border: none;
  padding: 4px 9px;
  border-radius: 5px;
  background-color: var(--quinary-color);
  color: var(--white);
  cursor: pointer;
  text-transform:uppercase;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  place-content: center;
  place-items: center;
  box-sizing: border-box;
  border: 3px solid transparent;
  font-weight: 700;
}
.button-basic:hover{
  opacity: 0.5;
}
.button-basic:disabled{
  cursor: default;
  opacity: 0.4  ;
}
.button-basic.off-link{
  background-color: transparent;
  color: var(--gray);
  text-transform: none;
  font-weight: normal;
  font-size: 11px;
  padding: 0;
  border-width: 1px;
  text-decoration: underline;
}
.button-basic.disabled-transparent:disabled{
  background-color: transparent;
  border: 3px solid var(--quinary-color)!important;
  color: var(--quinary-color)!important;
}



