.custom-select{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  border-radius:5px;
  opacity: 0.5;

 }
 .custom-select:hover{
    opacity: 1;
 }
 .custom-select.select-open{
   overflow: visible;
   opacity: 1;
 }
 .custom-select.select-open .custom-select__container{
   display: flex;
 }
 .custom-select__container{
   display: none;
   flex-direction: column;
   position: absolute;
   bottom:0;
   left:0;
   transform: translateY(100%);
   width: 100%;
   z-index: 100;
 }
 .custom-select__option:last-child{
   border-bottom-left-radius:5px ;
   border-bottom-right-radius: 5px;
 }
 .custom-select__selected:hover{
   opacity: 0.8;
   cursor: pointer;
 }
 
 .custom-select__selected{
   border:none;
   display: flex;
   place-content:space-between;
   gap:10px;
   border-top-left-radius:5px ;
   border-top-right-radius: 5px;
 }
 .custom-select__selected span{
   color:var(--tertiary-color);
   text-align: left;
   font-size: 14px;
   line-height: 21px;
   font-weight: 500;
 }
 .custom-select__selected svg{
   transform:rotate(-90deg);
   transition:all 0.2s ease-in-out
 }
 .custom-select.select-open .custom-select__selected svg{
   transform:rotate(0deg);
   transition:all 0.2s ease-in-out
 }
 .custom-select__selected,
 .custom-select__option{
   color:var(--tertiary-color);
   text-transform:capitalize;
   cursor:pointer;
   background-color: var(--white);
   padding:5px;
   text-align: left;
   font-size: 14px;
   line-height: 21px;
   font-weight: 500;
 }
 
.custom-select__option:hover{
  background-color: var(--tertiary-color);
  color: var(--white);
}

.custom-select.dark > .custom-select__selected{
  background-color: var(--tertiary-color);
  color:var(--white);
}
.custom-select.dark > .custom-select__selected span{
   color: var(--white);
}
.custom-select.dark .custom-select__option{
  background-color: var(--tertiary-color);
  color:var(--white);
}
.custom-select.dark .custom-select__option:hover{
  background-color: var(--white);
  color:var(--tertiary-color);
} 
.custom-select__option.checked {
  background-color: var(--white)!important;
  color:var(--tertiary-color)!important;
}
.custom-select.dark .custom-select__selected path {
  stroke:var(--white);
}