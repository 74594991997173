.text-basic,
.text-basic *{
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: var(--white);
  margin: 0;
  overflow-wrap: break-word;
}
.text-basic.landing *{
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: var(--white);
  margin: 0;
  overflow-wrap: break-word;
  letter-spacing: 1px;
}
.text-basic.terms *{
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: var(--white);
  margin: 10px;
  overflow-wrap: break-word;
}
.uppercase{
  text-transform: uppercase;
}
h3.text-basic,
h3.text-basic *{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
  margin: 10px 0;
}
h2.text-basic,
h2.text-basic *{
  color: var(--white);
  margin: 10px 0;
  font-size: 25px;
  line-height: 38px;
}
h1.text-basic,
.text-basic.h1{
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  color: var(--white);
  margin: 10px 0;
}
h1.text-basic *,
.text-basic.h1 *{
  font-size: 1em;
}
h1.text-basic::after{
  content:".";
  color:var(--quaternary-color);
  font-weight: 700;
  font-size: 1em;
}

.text-basic.xl{
  font-size: 60px;
  line-height: 65px;
}
.text-basic.center{
  text-align: center;
}
.bold{
  font-weight: 700!important;
}
@media screen and (max-width:375px) {
  h1.text-basic,
  h1.text-basic::after{
    font-size: 24px;
    line-height: 24px;
  }
  h1.text-basic.xl,
  h1.text-basic.xl::after{
    font-size: 36px;
    line-height: 36px;
  }
  h2.text-basic,
  h2.text-basic *{
    font-size: 18px;
    line-height: 27px;  
  }
}